import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import { ModalType } from '@movecloser/front-core';
import { EModal } from '@contract/modal';
import { Inject } from '@plugin/inversify';
import { Screen } from '@component/Screen';
import { AuthRepositoryType } from '../../contracts/repositories';
/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let ActivateAccountView = class ActivateAccountView extends Vue {
    constructor() {
        super(...arguments);
        this.isActing = false;
        this.isSuccess = null;
    }
    mounted() {
        this.isActing = true;
        const token = this.apiToken;
        this.authRepository.activate(token)
            .then(() => { this.isSuccess = true; })
            .catch(() => {
            this.isSuccess = false;
        })
            .finally(() => { this.isActing = false; });
    }
    get apiToken() {
        return this.$route.params.token;
    }
    onLogInBtnClick() {
        this.modalConnector.open(EModal.LogIn, {
            onSuccess: () => {
                this.$router.push('/');
            }
        });
    }
};
__decorate([
    Inject(AuthRepositoryType)
], ActivateAccountView.prototype, "authRepository", void 0);
__decorate([
    Inject(ModalType)
], ActivateAccountView.prototype, "modalConnector", void 0);
ActivateAccountView = __decorate([
    Component({
        name: 'ActivateAccountView',
        components: { Fragment, Screen },
        asyncData(context) {
            const { redirect, params } = context;
            const apiToken = params.token;
            if (typeof apiToken !== 'string' || apiToken.length === 0) {
                redirect({ path: '/' });
            }
            return {};
        }
    })
], ActivateAccountView);
export { ActivateAccountView };
export default ActivateAccountView;
